@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
#root {
  min-width: 100vw;
}
.flex {
  display: flex;
}
.v-flex {
  display: flex;
  flex-direction: column;
}
.user-table-body {
  border: green;
  height: fit-content;
}
.user-table-body td {
  /* border: red; */
}
.user-table-body td {
  position: relative;
}
.pendingSection {
  background: linear-gradient(
    to bottom,
    rgba(0, 175, 163, 0.302),
    #ffffff00,
    rgba(0, 175, 163, 0.302)
  );
}
.pendingSection * {
  opacity: 0;
  pointer-events: none;
}

.loadingSection {
  background: linear-gradient(
    45deg,
    rgba(39, 39, 39, 0.302),
    #ffffff00,
    rgba(35, 35, 35, 0.302)
  );
  background-size: 200% 200%;
  animation: loading 4s ease infinite;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0 !important;
}

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.loadingSection * {
  opacity: 0;
  pointer-events: none;
}

.sidebar {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  animation-name: sideanim;
  animation-duration: 500ms;
  animation-delay: 0;
}

@media only screen and (min-width: 992px) {
  .sidebar {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .bigScreen {
    display: none !important;
  }
}
.sidebar .mainSide {
  background-color: white;
  min-height: 100% !important;
  max-height: 100%;
  align-items: center;
  justify-content: space-between !important;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100px;
}
.sidebar .sideRight {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
}
.sidebar .sec2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content !important;
}

@keyframes sideanim {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.bigScreen {
  height: 100vh;
  margin: 0;
  position: sticky !important;
  top: 0;
  overflow-y: auto;
  width: 110px;
  display: flex;
  justify-content: center;
  background-color: white;
  z-index: 100;
}
.Mainnavbar .sec2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content !important;
}
.Mainnavbar .sec2 .hot {
  margin: 50% 0 50% 0 !important;
  height: fit-content !important;
}
.Mainnavbar .sec2 .menu {
  margin: auto !important;
}
.Mainnavbar .mainSide {
  background-color: white;
  align-items: center;
}
.bigScreen .sideRight {
  display: none !important;
  width: 0 !important;
}

.bigScreen .sec1 {
  height: fit-content !important;
  margin-top: 1rem;
}

.order-info {
  background: transparent !important;
  /* width: 50% !important */
}

.Mainnavbar .profilePop {
  box-shadow: 0 0 3px solid rgb(176, 176, 176) !important;
  background-color: white !important;
}
