.mobileSidebar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
}
.navContainer {
  width: 300px;
  background-color: white;
  height: 100%;
  padding: 0 0 1.5rem 0;
  z-index: 111;
  overflow-y: auto;
}
.navSection {
}
.rightSide {
  width: 100%;
  height: 100%;
}
.sideBottom {
  border-top: 2px solid rgb(36, 32, 59);
  margin-top: 4rem;
  height: 190px;
  padding-bottom: 5px;
}
.hodlpayLogo {
  border-bottom: 2px solid rgb(36, 32, 59);
  width: 100%;
  min-height: 170px;
  background-color: rgb(43, 41, 75);
  position: sticky;
  top: 0;
}
.logoContainer {
  width: 60%;
  height: 65px !important;
  /* border: 3px solid violet */
}

.navItem {
  width: 90% !important;
  border-radius: 5px;
  background: rgb(226, 226, 226, 0.5);
  height: 48px;
}

.navItemActive {
  background-color: rgb(43, 41, 75) !important;
  border-radius: 10px;
  height: 48px;
}
.navItemActive * {
  color: white !important;
}

.closeBtn {
  top: 1rem;
  left: 0.5rem;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  z-index: 122;
}

.accPop {
  width: 290px !important;
}

@media only screen and (max-width: 400px) {
  .rightSide {
    width: 0;
    height: 100%;
  }
  .navContainer {
    width: 100%;
  }

  .navItem {
    background: rgb(226, 226, 226, 0.5);

    /* margin-top: 0.5rem  !important; */
    width: 70% !important;
  }

  .closeBtn {
    display: flex;
  }

  .accPop {
    width: 97% !important;
  }
}
