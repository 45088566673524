@media only screen and (max-width: 777px) {
  .kycCard {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: none !important;
  }
  .kycLevel {
    background-color: rgb(7, 200, 199, 0.05);
    /* color: white; */
    padding: 2rem !important;
    border-radius: 5px;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .kycLevel aside:nth-child(2) {
    margin: 1rem 0 0 0 !important;
  }
}

@media only screen and (max-width: 410px) {
  .kycCard h5 {
    display: none !important;
  }
  .kycLevel {
    padding: 1rem !important;
    border-radius: 5px;
  }
  .kycLevel p {
    margin-bottom: 0.2rem !important;
  }
  .kycLevel p b {
    font-size: 1.1rem !important;
  }
  .kycLevel aside:nth-child(2) {
    margin: 1.5rem 0 0 0 !important;
  }
}

.personalInfoContainer {
  justify-content: space-between;
  height: fit-content !important;
}
.personalInfo {
  height: 100% !important;
}
.personalInfo,
.paymentMethod {
  margin: 0 !important;
  min-height: 550px;
  width: 49%;
}

@media only screen and (max-width: 820px) {
  .personalInfoContainer {
    flex-direction: column;
  }
  .personalInfo,
  .paymentMethod {
    width: 100%;
    height: fit-content !important;
    margin: 0 0 2rem 0 !important;
  }
}
