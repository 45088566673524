@media only screen and (max-width: 650px) {
  .alertInfo,
  .modal {
    max-width: 98% !important;
    min-width: 98% !important;
    width: 98% !important;

    padding: 2rem !important;
  }
}
