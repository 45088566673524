@media only screen and (max-width: 610px) {
  .order {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .hasPending {
    min-width: 90% !important;
    max-width: 90% !important;
  }
}
