@media only screen and (max-width: 1090px) {
  .admin-settingsContent {
    flex-direction: column !important;
  }
  .admin-settingsContent form:nth-child(2) {
    margin: 2rem 0 0 0 !important;
  }
  .cont {
    margin-left: 0 !important;
    margin-top: 1rem !important;
  }
}
